<template>
    <div class="row">
        <div class="col-12" >
            <g-loading v-if="firstLoading" />
            <g-table v-else :filter="filterFormModel" :itemCount="itemCount" :items="items" :fields="fields" @load="load" @reset="reset"
                     title="Giao diện dashboard" titleIcon="fa fa-pager">
                <template slot="action">
                    <g-button class="ml-2" v-if="permissions('config-dashboard_create')" :loading="saveLoading" size="sm" variant="success" icon="plus" content="Thêm mới" @click='create' />
                </template>
                <template slot="filter">
                    <form-select :model="filterFormModel" class="mr-2" attribute="type" :isFilter="true" @change="search" :options="$params.client.typeOptions"  />
                </template>
                
                <template #cell(type)="{item}">
                    <span v-if='item.type && $params.subscribeOptions.find(o => o.value === item.type)'>{{$params.subscribeOptions.find(o => o.value === item.type).text}}</span> 
                </template>
                <template #cell(target_id)="{item}">
                   <span v-if="item.type == '4' && params.topics && params.topics[item.target_id]">{{params.topics[item.target_id]}} </span>
                   <span v-if="item.type == '3' && params.docs && params.docs[item.target_id]">{{params.docs[item.target_id]}}</span>
                   <span v-if="item.type == '2' && params.posts && params.posts[item.target_id]">{{params.posts[item.target_id]}}</span>
                </template>
                <template #cell(action)="{item}">
                    <div class="btn-action-3" >
                        <g-button v-if="permissions('subscribe_update')" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="pencil" @click="update(item.id)" />
                        <g-button  v-if="permissions('subscribe_del')" :loading="delLoading" size="sm" variant="outline-danger" class="ml-1 mb-1" icon="trash-fill" @click='del(item.id)' />
                    </div>
                </template>
            </g-table>
        </div>
        <b-modal v-model="showForm" :title="formTitle" @ok="save" ok-title="Lưu lại" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>

            <form-input label="Họ và tên" :model="formModel"   :errors="formErrors" attribute="fullname" ></form-input>
            <form-input label="Email" :model="formModel" :required='true' :errors="formErrors" attribute="email" ></form-input>
            <form-select @change="changeType()" label="Loại đăng ký" :required='true' :model="formModel" attribute="type" :errors="formErrors" :options="$params.subscribeOptions"  />

            <post-select v-if="formModel.type == '2'" label="Bài viết"  :model="formModel" :errors="formErrors" attribute="target_id" :required='true' />
            <doc-select  v-if="formModel.type == '3'"  label="Tài liệu"  :model="formModel" :errors="formErrors" attribute="target_id" :required='true' />
            <doc-topic-select v-if="formModel.type == '4'"  label="Chủ đề"  :model="formModel" :errors="formErrors" attribute="target_id" :required='true' />

            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="ok">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    <em v-else class="fa fa-save" ></em>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    import role from '@/mixins/Role';
    export default {
        components: {},
        mixins: [list, crud,role],
        data: function () {
            return {
                service: '/config-dashboard',
                fields: [
                    {key: 'fullname', label: "Họ và tên"},
                    {key: 'email', label: "Email"},
                    {key: 'type', label: "Loại đăng ký", class: 'text-left'},
                    {key: 'target_id', label: "Bài viết/Tài liệu", class: 'text-left'},
                    {key: 'action', label: "Hành động", class: 'text-right'},
                ],
                defaultFilterFormModel: {
                    keyword: '',
                    type: null
                },
                defaultFormModel: {
                    type: null
                },
            };
        },
        methods: {
            changeType(){
                this.formModel.target_id = null;
            }

        }
    }
</script>